import { Fragment } from 'react';
import styles from './App.module.scss';
import { SocialButton, Container, Card, Text } from './components';
import avatarImage from './images/avatar2.jpg'

function App() {
  const cardLeftContent = () => {
    return (
      <Fragment>
        <Text type="heading">
          Josh Chaiken
        </Text>
        <Text type="subhead">
          Design Systems @ Morgan Stanley
        </Text>
        <Text type="body">
          👋 I believe in fostering a collaborative environment to deliver best-in-class experiences and bringing together the best minds ensuring a creative environment coupled.
        </Text>
        <ul className={styles.buttonGroup}>
          <li><SocialButton url="//linkedin.com/in/joshchaiken/" service="linkedin" /></li>
          <li><SocialButton url="//github.com/scatteredbra1n" service="github" /></li>
          <li><SocialButton url="mailto:me@joshchaiken.com" service="email" /></li>
          <li><SocialButton url="//youtube.com/scatteredbrain" service="youtube" /></li>
          <li><SocialButton url="//dribbble.com/jchaike" service="dribbble" /></li>
        </ul>
      </Fragment>
    );
  }



  return (
    <Container>
      <Card leftContent={cardLeftContent()} avatar={avatarImage}></Card>
    </Container>
  );
}

export default App;
