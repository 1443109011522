import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { string, node } from 'prop-types';

const ComponentProps = {
	leftContent: node,
	avatar: string
};

const Card = (props) => {
	const { leftContent, avatar } = props;

	const leftCardCls = classNames(styles.cardColumn, styles.cardDescription)
	// const rightCardCls = classNames(styles.cardColumn, styles.cardAvatar)

	return (
		<div className={styles.cardWrapper}>
			<div className={styles.cardContent}>
				<div className={leftCardCls}>
					{ leftContent }
				</div>
				<div className={styles.cardColumn}>
					<div className={styles.cardAvatar} style={{backgroundImage: `url(${avatar})`}}/>
				</div>
			</div>
		</div>
	)
}

Card.propTypes = ComponentProps;

export default Card;