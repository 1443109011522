import React from 'react';
import styles from './styles.module.scss';
import { oneOf, node } from 'prop-types';

const ComponentProps = {
	type: oneOf(['heading', 'subhead', 'body']),
	children: node
};

const DefaultProps = {
	type: 'body'
};

const Text = (props) => {
	const { type, children } = props;
	const cls = styles[type];
	const Tag = () => {
		switch (type) {
			case 'heading':
				return <h1 className={cls}>{children}</h1>
			case 'subhead':
				return <p className={cls}>{children}</p>
			default:
				return <div className={cls}>{children}</div>
		}
	}
	return (
		<Tag className={cls}>{children}</Tag>
	)
}

Text.propTypes = ComponentProps;
Text.defaultProps = DefaultProps;

export default Text;