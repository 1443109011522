// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./images/avatar2.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_buttonGroup__1u-Ua {\n  list-style: none;\n  text-align: center;\n}\n@media (min-width: 767px) {\n  .App_buttonGroup__1u-Ua {\n    text-align: left;\n  }\n}\n.App_buttonGroup__1u-Ua li {\n  display: inline-block;\n  padding-right: 15px;\n}\n.App_buttonGroup__1u-Ua li:last-child {\n  padding-right: 0;\n}\n\n.App_avatar__2BkpT {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n}", "",{"version":3,"sources":["webpack://src/App.module.scss","webpack://src/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,kBAAA;AADF;ACQI;EDTJ;IAII,gBAAA;EACF;AACF;AAAE;EACE,qBAAA;EACA,mBAAA;AAEJ;AADI;EACE,gBAAA;AAGN;;AAEA;EACE,yDAAA;EACA,4BAAA;EACA,wBAAA;AACF","sourcesContent":["@import 'mixins';\n\n.buttonGroup {\n  list-style: none;\n  text-align: center;\n  @include breakpoint(sm) {\n    text-align: left\n  }\n  li {\n    display: inline-block;\n    padding-right: 15px;\n    &:last-child {\n      padding-right: 0;\n    }\n  }\n}\n\n.avatar {\n  background-image: url('./images/avatar2.jpg');\n  background-repeat: no-repeat;\n  background-size: contain;\n}","$breakpoints: (\n  'sm':  767px,\n  'md': 992px,\n  'lg':  1200px,\n  'xl': 1600px\n);\n\n@mixin breakpoint($breakpoint) {\n  // If the key exists in the map\n  @if map-has-key($breakpoints, $breakpoint) {\n    // Prints a media query based on the value\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  }\n \n  // If the key doesn't exist in the map\n  @else {\n    @warn \"Unfortunately, no value could be retrieved from `#{$breakpoint}`. \"\n        + \"Available breakpoints are: #{map-keys($breakpoints)}.\";\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": "App_buttonGroup__1u-Ua",
	"avatar": "App_avatar__2BkpT"
};
export default ___CSS_LOADER_EXPORT___;
