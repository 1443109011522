import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { oneOf, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faDribbble, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faQuestion } from '@fortawesome/free-solid-svg-icons'

const ComponentProps = {
	service: oneOf(['github', 'dribbble', 'email', 'linkedin']),
	url: string
};

const SocialButton = (props) => {
	const { service, url } = props;

	const cls = classNames(styles.buttonSocial, styles[service]);

	const getIcon = () => {
		switch (service) {
			case 'github':
				return faGithub;
			case 'dribbble':
				return faDribbble;
			case 'linkedin':
				return faLinkedin;
			case 'email':
				return faEnvelope;
			case 'youtube':
				return faYoutube;
			default:
				return faQuestion;
		}
	}

	return (
		<a href={url} aria-label={`link to ${service}`} className={cls}><FontAwesomeIcon icon={getIcon()} /></a>
	)
}

SocialButton.propTypes = ComponentProps;

export default SocialButton;