// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/background-pattern.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2xIAm {\n  height: 100%;\n  z-index: 0;\n  margin: 0;\n}\n.styles_container__2xIAm .styles_wrapper__U4vw5 {\n  height: 100%;\n  width: 100%;\n}\n.styles_container__2xIAm .styles_wrapper__U4vw5::before, .styles_container__2xIAm .styles_wrapper__U4vw5::after {\n  content: \"\";\n  height: 100%;\n  width: 100%;\n  position: fixed;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: bottom left;\n  background-size: contain;\n  filter: blur(5px);\n  top: 0;\n  z-index: 0;\n}\n.styles_container__2xIAm .styles_wrapper__U4vw5:after {\n  transform: scaleY(-1) scaleX(-1);\n}", "",{"version":3,"sources":["webpack://src/components/Container/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,SAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ;AADI;EAEE,WAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,yDAAA;EACA,4BAAA;EACA,gCAAA;EACA,wBAAA;EACA,iBAAA;EACA,MAAA;EACA,UAAA;AAEN;AAAI;EACE,gCAAA;AAEN","sourcesContent":[".container {\n  height: 100%;\n  z-index: 0;\n  margin: 0;\n  .wrapper {\n    height: 100%;\n    width: 100%;\n    &::before,\n    &::after {\n      content: \"\";\n      height: 100%;\n      width: 100%;\n      position: fixed;\n      background-image: url('../../images/background-pattern.png');\n      background-repeat: no-repeat;\n      background-position: bottom left;\n      background-size: contain;\n      filter: blur(5px);\n      top: 0;\n      z-index: 0;\n    }\n    &:after {\n      transform: scaleY(-1) scaleX(-1);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2xIAm",
	"wrapper": "styles_wrapper__U4vw5"
};
export default ___CSS_LOADER_EXPORT___;
